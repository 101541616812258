/** @format */

const workExperiences = [
    {
        date: "2024 - PRESENT",
        isCurrent: true,
        title: "Maytorena Developments",
        subtitle:
            "Founded a software development agency focused on B2B and B2C softwares which include: dashboards, mobile apps, and backend infrastructures.",
        tags: ["Python", "SwiftUI", "Postgres", "React", "PHP", "Expo"],
    },
    {
        date: "2023 - 2024",
        isCurrent: false,
        title: "Co-founder & Developer @ Matche",
        subtitle:
            "Developed a mobile app designed to provide a centralized booking solution for tennis courts in London.",
        tags: ["Python", "SwiftUI", "MongoDB", "SocketIO", "Javascript"],
    },
    {
        date: "2022 - 2023",
        isCurrent: false,
        title: "Founder @ Ghost Developments",
        subtitle:
            "Development of automation tools and sold as monthly services to individuals and companies.",
        tags: ["Python", "MySQL"],
    },
];

export default function WorkExperienceSection() {
    return (
        <div className="pt-20 flex flex-col">
            <h2 className="font-semibold font-inter text-lg pb-4">
                Work Experience
            </h2>
            <div className="flex flex-col space-y-5">
                {workExperiences.map((item) => (
                    <div className="flex flex-col sm:flex-row items-start">
                        <span className="text-[#656565] text-sm w-44 items-center flex">
                            {" "}
                            {item.isCurrent && (
                                <div className="bg-accent h-2 w-2 rounded-xl absolute -ml-5"></div>
                            )}
                            {item.date}
                        </span>
                        <div className="">
                            <span className="font-inter font-semibold">
                                {item.title}
                            </span>
                            <p className="md:w-96 text-[#323232] text-sm font-normal pt-2">
                                {item.subtitle}
                            </p>
                            <div className="flex flex-wrap gap-2 pt-3 pb-2">
                                {item.tags.map((tag, index) => (
                                    <span
                                        key={index}
                                        className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-xs"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
