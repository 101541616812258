/** @format */
import { FaArrowLeft } from "react-icons/fa6";
import { projects } from "../../components/projects";

export default function ProjectsPage() {
    return (
        <div className="pt-6 pb-10 flex flex-col justify-center w-screen items-center">
            <div className="flex flex-row justify-between pb-4 items-center">
                <a href="/" className="pr-20">
                    <FaArrowLeft className="w-5 hover:text-accent transition-all cursor-pointer" />
                </a>
                <h2 className="font-semibold font-inter text-lg text-left items-start pr-24">
                    Projects
                </h2>
            </div>

            <div className="sm:space-y-4 space-y-6 sm:px-0 px-5 pt-4">
                {projects.map((item) => (
                    <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col sm:flex-row items-start group sm:hover:bg-gray-100 sm:p-2 rounded-md transition-all cursor-pointer"
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className="w-full sm:w-64 h-full object-cover rounded-md"
                        />
                        <div className="sm:max-w-96 sm:pl-4 sm:pt-0 pt-2">
                            <span className="font-inter font-semibold group-hover:text-accent">
                                {item.title}
                            </span>
                            <p className="md:w-96 text-[#323232] text-sm font-normal pt-1">
                                {item.subtitle}
                            </p>
                            <div className="flex flex-wrap gap-2 pt-3 pb-2">
                                {item.tags.map((tag, index) => (
                                    <span
                                        key={index}
                                        className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-xs"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}
