/** @format */

import AboutSection from "./sections/about";
import ProjectsSection from "./sections/projects";
import WorkExperienceSection from "./sections/workExperiences";

export default function HomePage() {
    return (
        <div className="flex flex-col lg:flex-row items-start mx-6 sm:mx-16 md:mx-24 lg:mx-32 justify-between">
            <AboutSection />
            <div className="">
                <WorkExperienceSection />
                <ProjectsSection />
            </div>
        </div>
    );
}
