/** @format */

import StripeImage from "../assets/stripeimage.png";
import MatcheAppImage from "../assets/matcheimage.png";
import URVAImage from "../assets/urvaimage.png";
import KV01Image from "../assets/kv01image.png";
import GoKartImage from "../assets/gokartdashimage.png";
import GoKartAppImage from "../assets/gokartimage.png";
import NoctoImage from "../assets/noctoimage2.png";
import CKMImage from "../assets/ckmgimage.png";
import CRImage from "../assets/comevimage.png";
import SSGImage from "../assets/ssgimage.png";

export const projects = [
    {
        image: StripeImage,
        title: "Stripe Dashboard Clone",
        subtitle:
            "A clone of the Stripe dashboard, includes authentication management, and dynamically loaded data from a REST API with test data.",
        link: "https://stripe-web-app.vercel.app/",
        tags: ["React", "Tailwind CSS", "Python"],
    },
    {
        image: URVAImage,
        title: "Warehouse Management Dashboard",
        subtitle:
            "A production dashboard to manage inventory and shipments at a warehouse.",
        link: "https://urvafreight.com",
        tags: ["React", "Tailwind CSS", "Typescript", "Python"],
    },
    {
        image: SSGImage,
        title: "Online Membership Management Dashboard",
        subtitle:
            "A dashboard that allows users and administrators to manage their subscriptions to an online group. Integration with stripe for payments and referrals.",
        link: "https://simplesaucegroup.com/",
        tags: ["React", "Python", "Postgres", "Discord API"],
    },
    {
        image: MatcheAppImage,
        title: "Matche Start Up",
        subtitle:
            "A mobile app that allows users to book tennis, padel and football courts in London. Developed the mobile app, backend and complementary website pages.",
        link: "https://matche.app",
        tags: ["React", "Tailwind CSS", "Python", "Postgres"],
    },
    {
        image: KV01Image,
        title: "KV-01 - Stocks Website",
        subtitle:
            "Built a website during my internship at a hedgefund, Marcho Partners. It allows people to view stocks and their vibes.",
        link: "https://www.kv01.com/",
        tags: ["NextJS", "Typescript", "Supabase"],
    },
    {
        image: GoKartImage,
        title: "Go Kart Management Dashboard",
        subtitle:
            "Developed a SaaS for go karting companies, it facilitates keeping track of bookings and customers.",
        link: "https://www.gokartcontrol.com/",
        tags: ["Python", "React", "Typescript"],
    },
    {
        image: NoctoImage,
        title: "Nocto Software",
        subtitle:
            "An automation software that helped users purchase limited apparel, 1000+ active users at it's peak.",
        link: "https://x.com/NoctoTools",
        tags: ["Python", "SocketIO"],
    },
    {
        image: CKMImage,
        title: "Cincinnati Krav Maga Mobile App",
        subtitle:
            "A mobile app used by a gym to allow users to view training schedules and important notifications.",
        link: "https://apps.apple.com/us/app/cincinnati-krav-maga/id6504111268",
        tags: ["PHP", "React Native", "Expo", "SwiftUI"],
    },
    {
        image: CRImage,
        title: "Community Retirement Mobile App",
        subtitle:
            "An app that allows elderly people at retirement communities to view and rsvp to events.",
        link: "",
        tags: ["SwiftUI", "Python", "Google API"],
    },
    {
        image: GoKartAppImage,
        title: "Go Kart Booking App",
        subtitle:
            "A mobile app that allows users to view their gokart races and book a gokart race.",
        link: "https://www.gokartcontrol.com/",
        tags: ["SwiftUI", "Python"],
    },
];
