/** @format */

import { projects } from "../../../components/projects";

export default function ProjectsSection() {
    return (
        <div className="pt-6 pb-10">
            <div className="flex flex-row items-center justify-between pb-4">
                <h2 className="font-semibold font-inter text-lg">Projects</h2>
                <a href="/projects" className="text-sm font-medium text-accent">
                    See All
                </a>
            </div>

            <div className="sm:space-y-4 space-y-6">
                {projects.slice(0, 5).map((item) => (
                    <a
                        href={item.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex flex-col sm:flex-row items-start group sm:hover:bg-gray-100 sm:p-2 rounded-md transition-all cursor-pointer"
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className=" w-full sm:w-44 h-full object-cover rounded-md"
                        />
                        <div className="sm:max-w-96 sm:pl-4 sm:pt-0 pt-2">
                            <span className="font-inter font-semibold group-hover:text-accent">
                                {item.title}
                            </span>
                            <p className="md:w-96 text-[#323232] text-sm font-normal pt-1">
                                {item.subtitle}
                            </p>
                            <div className="flex flex-wrap gap-2 pt-3 pb-2">
                                {item.tags.map((tag, index) => (
                                    <span
                                        key={index}
                                        className="flex px-3 h-6 items-center justify-center bg-accent bg-opacity-20 text-accent rounded-xl font-medium text-xs"
                                    >
                                        {tag}
                                    </span>
                                ))}
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            <div className="pt-4 flex flex-row items-center justify-center">
                <a href="/projects" className="text-sm font-medium text-accent">
                    See All Projects
                </a>
            </div>
        </div>
    );
}
