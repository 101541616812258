/** @format */

import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const socialIcons = [
    {
        icon: FaGithub,
        href: "https://github.com/andremaytorena",
    },
    {
        icon: FaLinkedin,
        href: "https://www.linkedin.com/in/andre-maytorena-7260212a5",
    },
    {
        icon: FaXTwitter,
        href: "https://x.com/andremaytorena",
    },
];

export default function AboutSection() {
    return (
        <div className="pt-20 sm:pt-32 flex flex-col overscroll-y-none sm:sticky sm:top-0">
            <span className="text-accent font-semibold text-xl font-inter">
                Hi, my name is
            </span>
            <span className="text-black text-4xl font-bold font-inter pt-2">
                Andre Maytorena
            </span>
            <p className="sm:w-96 font-medium text-[#484848] pt-6">
                I'm a software developer who's passionate for developing full
                stack applications. Currently working @{" "}
                <a
                    className="text-accent"
                    href="https://www.linkedin.com/company/maytorena-developments"
                >
                    Maytorena Developments
                </a>
                .
            </p>
            <div className="pt-6 flex flex-row items-center space-x-5">
                <button
                    onClick={() =>
                        (window.location = "mailto:andremayto@gmail.com")
                    }
                    className="border border-accent w-32 h-10 rounded-xl hover:bg-accent-200 transition-all"
                >
                    <span className="text-accent font-medium">Contact Me</span>
                </button>
                <a
                    href="https://drive.google.com/file/d/17YvHDcPcEwWuw0qiO3depKxmqU36A4mS/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <button className="bg-accent w-32 h-10 rounded-xl transition-all hover:bg-accent-200">
                        <span className="text-white font-medium">Resume</span>
                    </button>
                </a>
            </div>

            <div className="flex flex-row items-center space-x-4 pt-10">
                {socialIcons.map((item) => (
                    <a
                        href={item.href}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="space-y-2"
                    >
                        <item.icon
                            className="text-gray-600 hover:text-gray-900 transition-colors"
                            size={26}
                        />
                    </a>
                ))}
            </div>
        </div>
    );
}
