/** @format */

import "./index.css";
import "@fontsource/inter"; // Defaults to weight 400
import "@fontsource/inter/400.css"; // Specify weight
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/home/page";
import ProjectsPage from "./pages/projects/page";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/projects" element={<ProjectsPage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
